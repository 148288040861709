<template>
  <div class="task-completion">
    <div class="top-bar">
      <v-btn
          icon
          @click="$router.push({name: 'tasks'})"
          class="absolute"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <h1 class="text--primary">{{ $store.state.core.title }}</h1>
    </div>

    <v-overlay :value="loadingTask" class="text-center" opacity="0.75">
      <v-progress-circular indeterminate/>
      <p>
        {{ $t('loading_tasks') }}
      </p>
    </v-overlay>

    <div class="task-info mb-6" v-if="task">
      <div class="mb-3">
        <span class="label mr-3 secondary-16">{{ $t('client') }}</span>
        <span class="label primary-16">{{ task.client_name }} ({{ task.client_id }})</span>
      </div>
      <div class="mb-3">
        <span class="label mr-3 secondary-16">{{ $t('site') }}</span>
        <span class="label primary-16">{{ task.site }}</span>
      </div>
      <div class="mb-3">
        <span class="label mr-3 secondary-16">{{ $t('hub') }}</span>
        <span class="label primary-16">{{ task.hub }}</span>
      </div>
      <div class="mb-3">
        <span class="label mr-3 secondary-16">{{ $t('location') }}</span>
        <span class="label primary-16">{{ task.location }}</span>
      </div>
      <div class="">
        <span class="label mr-3 secondary-16">{{ $t('p-number') }}</span>
        <span class="label primary-16">{{ task.sku }}</span>
      </div>
    </div>

    <div class="actions" v-if="task">
      <v-text-field
          :label="$t('quantity')"
          type="number"
          v-model="quantity"
          outlined
          color="secondary"
      ></v-text-field>

      <v-btn
          block
          color="secondary"
          :disabled="!canSubmit"
          @click="submit"
          class="font-weight-medium text--primary"
      >
        {{ $t('complete') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      loadingTask: false,
      quantity: null,
    }
  },
  methods: {
    async getTask() {
      this.loadingTask = true
      try {
        await this.$store.dispatch('tasks/getTask', this.$route.params.id)
        this.$store.commit('core/setTitle', this.$t('task') + ' ' + this.idLabel)
        this.quantity = this.task.actual_quantity;
      } finally {
        this.loadingTask = false
      }
    },

    async submit() {
      this.loadingTask = true
      try {
        await this.$store.dispatch('tasks/completeTask', {id: this.task.task_id, quantity: this.quantity})
      } finally {
        this.loadingTask = false
      }
      await this.$router.push({name: 'tasks'})
    },
  },
  computed: {
    task () {
      return this.$store.state.tasks.currentTask;
    },

    idLabel () {
      if (!this.task.task_id || !this.task.request_id) {
        return '';
      }

      let id = this.task.request_id.toString().padStart(4, '0');
      id += '-';
      id += this.task.sequence_id.toString().padStart(2, '0');

      return id;
    },

    canSubmit () {
      return !!this.quantity;
    }
  },
  created () {
    this.getTask()
  }
}
</script>

<style scoped lang="scss">
  .task-completion {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;

    .top-bar {
      border-top: 5px solid #3BD4AE;
      margin-bottom: 15px;
      padding-top: 10px;
      display: flex;
      align-items: center;

      h1 {
        font-size: 1.5rem;
        font-weight: normal;
        margin: 0 auto;
      }
    }

    .task-info {
      padding: 0 20px;
      //line-height:35px;
      .label {
        margin-right: 20px;
      }
    }

    .v-label--active {
      color: #292D4F;
    }

    .actions {
      padding: 0 15px;
      margin-top: 15px;
    }
  }
</style>
