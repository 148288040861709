import Vue from 'vue'
import Vuex from 'vuex'
import core from '../store/core'
import tasks from '../store/tasks'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const store = new Vuex.Store({
        modules: {
            core: core,
            tasks: tasks
        },
        // plugins: [
        //     createPersistedState()
        // ]
    }
)

export default store