import LocalisationService from './services/LocalisationService';
import LocalisationHandler from './components/LocalisationHandler';

export default {
    install (Vue, options) {
        const {axios, i18n, domainUrl, setLanguageHandler} = options;
        Vue.prototype.$localise = new LocalisationService(axios, i18n, domainUrl, setLanguageHandler);
        Vue.component('localisation-handler', LocalisationHandler);
    },
};
