import Vue from "vue"
import setState from "@/utilities/store"
import { api } from "@/bootstrap/api"

const defaultState = () => ({
    tasks: [],
    total: 0,
    page: 1,
    perPage: 10,
    currentTask: null,
})

export default {
    state: defaultState(),
    mutations: {
        updateTask(state, task) {
            let index = state.tasks.findIndex(old => old.task_id === task.task_id)
            if (index >= 0) {
                Vue.set(state.tasks, index, task)
            } else {
                console.error('Unknown task: ' + task.task_id)
            }
        },
        setCurrentTask(state, task) {
            state.currentTask = task
        },
        setPage(state, page) {
            state.page = page
        },
        setPerPage(state, perPage) {
            state.perPage = perPage
        },
        setTasks(state, tasks) {
            state.tasks = tasks
        },
        setTotal(state, total) {
            state.total = total
        },
        resetState(state) {
            setState(state, defaultState())
        },
    },
    actions: {
        async completeTask({commit}, {id, quantity}) {
            let params = {
                id: id
            }
            let data = {
                actualQuantity: quantity
            }
            const results = await api('completeTask', {data: data, params: params})
            commit('updateTask', results.data)
        },
        async getTasks({state, commit, rootGetters}) {
            let params = {
                filter: [
                    {
                        name: 'status',
                        operator: '<>',
                        value: 'complete'
                    },
                    {
                        name: 'assigned_user_id',
                        operator: '=',
                        value: rootGetters["core/getAuthUserId"]
                    }
                ],
                sort_name: 'request.due_date',
                sort_direction: 'asc',
                current_page: state.page,
                per_page: state.perPage,
            }
            const results = await api('tasks', {params: params})
            commit('setTasks', results.data.data)
            commit('setTotal', results.data.total)
            commit('setPerPage', results.data.per_page)
        },
        async getTask({commit}, id) {
            const results = await api(`task`, {params: { id }})
            commit('setCurrentTask', results.data)
        }
    },
    namespaced: true
}
