import {API_BASE_URL, API_CLIENT_ID, CLIENT_CALLBACK_BASE_URL} from '@/bootstrap/config'

export default {
    base_url: API_BASE_URL,
    client_id: API_CLIENT_ID,
    client_callback_base_url: CLIENT_CALLBACK_BASE_URL,
    endpoints: {
        accessToken: {
            method: 'post',
            url: '/oauth/token'
        },
        refreshToken: {
            method: 'post',
            url: '/oauth/token',
            data: {
                grant_type: 'refresh_token',
                scope: ''
            }
        },
        authUser: {
            method: 'get',
            url: '/api/v1/user'
        },
        completeTask: {
            method: 'patch',
            url: '/api/v1/stock-control/tasks/{id}/complete'
        },
        tasks: {
            method: 'get',
            url: '/api/v1/stock-control/tasks'
        },
        task: {
            method: 'get',
            url: '/api/v1/stock-control/tasks/{id}'
        },
        logout: {
            method: 'get',
            url: '/api/v1/user/logout'
        },
    }
}
