import {api, fetchAccessToken} from '@/bootstrap/api'
import router from '@/bootstrap/router'
import moment from 'moment'
import setState from '@/utilities/store'
import {SESSION_TIMEOUT} from "@/bootstrap/config"
import i18n from '@/plugins/i18n';
import config from "@/api/config";

const defaultState = () => ({
    accessToken: null,
    accessTokenExpiresAt: null,
    authUser: null,
    errors: [],
    title: null,
    activeSessionTime: null,
})


export default {
    state: defaultState(),
    getters: {
        getTitle(state) {
            return state.title ? state.title : i18n.t('stock_control')
        },
        getAuthUserId(state) {
            return state.authUser ? state.authUser.id : null
        },
        isAuthenticated(state) {
            return !!state.accessToken && !!state.authUser
        },
    },
    mutations: {
        setErrors(state, errors) {
            state.errors = errors
        },
        addError(state, error) {
            state.errors.push(error)
        },
        setAccessToken(state, token) {
            state.accessToken = token
            state.accessTokenExpiresAt = moment().add(token.expires_in, 'seconds')
        },
        setAuthUser(state, authUser) {
            state.authUser = authUser
        },
        setTitle(state, title) {
            state.title = title
        },
        resetState(state) {
            setState(state, defaultState())
        },
        setActiveSessionTime(state) {
            state.activeSessionTime = moment()
        },
    },
    actions: {
        resetErrors({commit}) {
            commit('setErrors', [])
        },
        redirect({dispatch}, routeInfo) {
            if (router.currentRoute.name !== routeInfo?.name) {
                dispatch('resetErrors')
                router.push(routeInfo)
            }
        },
        handleError({commit, dispatch}, e) {
            if (typeof e.response !== 'undefined') {
                if (e.response.status === 401 || e.response.status === 419) {
                    dispatch('logout')
                }
            }
            commit('addError', e)
        },
        checkActiveSession({state, dispatch}) {
            if (
                state.activeSessionTime &&
                moment(state.activeSessionTime).add(SESSION_TIMEOUT, 'milliseconds') < moment()
            ) {
                dispatch('logout')
            }
        },
        logoutAuth(){
            window.location.href = config.base_url + '/logout?redirect=' + config.client_callback_base_url;
        },
        async fetchAccessToken({commit}, {codeVerifier, code}) {
            const token = await fetchAccessToken(codeVerifier, code)
            if (token) {
                commit('setAccessToken', token)
            }
            return token
        },
        async logout({dispatch, commit, getters}) {
            if(getters.isAuthenticated){
                await api('logout', {handleError: false})
                commit('resetState')
                commit('tasks/resetState', null, {root: true})
                dispatch('logoutAuth')
            }
            dispatch('redirect', {name: 'login'})
        },
        async getAuthUser({commit}) {
            const results = await api('authUser', {handleError: false})
            const authUser = results.data
            commit('setAuthUser', authUser)
        },
    },
    namespaced: true
}
