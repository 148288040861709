import Login from '@/components/pages/Login'
import Home from '@/components/pages/Home'
import Tasks from '@/components/pages/Tasks'
import TaskCompletion from '@/components/pages/TaskCompletion'
import i18n from '@/plugins/i18n'
import Settings from "../components/pages/Settings";
import Callback from "@/components/pages/Callback";

export default [
    {
        name: 'login',
        path: '/login',
        component: Login,
        meta: {
            title() {
                return i18n.t('login')
            }
        }
    },
    {
        name: 'callback',
        path: '/callback',
        component: Callback,
        meta: {
            title() {
                return i18n.t('login')
            }
        }
    },
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            title() {
                return i18n.t('home')
            }
        }
    },
    {
        name: 'tasks',
        path: '/tasks',
        component: Tasks,
        meta: {
            title() {
                return i18n.t('tasks')
            }
        }
    },
    {
        name: 'task-completion',
        path: '/task/:id',
        component: TaskCompletion,
        meta: {
            title() {
                return i18n.t('task')
            }
        }
    },
    {
        name: 'settings',
        path: '/settings',
        component: Settings,
        props: true,
        meta: {
            title () {
                return i18n.t('settings')
            }
        }
    }
]
