import VueRouter from 'vue-router'
import Vue from "vue"
import routes from '../routes/index'
import store from '../bootstrap/store'
import {isFunction} from 'lodash'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (
        !store.getters['core/isAuthenticated'] &&
        to.name !== 'login' &&
        to.name !== 'callback'
    ) {
        next({name: 'login'})
        return
    }
    next()
})

router.afterEach((to) => {
    if (to.meta?.title) {
        store.commit('core/setTitle', isFunction(to.meta.title) ? to.meta.title() : to.meta.title)
    } else {
        store.commit('core/setTitle', null)
    }
})

export default router
