<template>
  <div>
    <v-form @submit.prevent="submit">
      <v-select
          id="font-size"
          v-model="zoomScale"
          :items="items"
          :label="$t('font_size')"
          @change="submit"></v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      zoomScale: 1,
      items: [
        {
          text: this.$t('standard'),
          value: 1,
        },
        {
          text: this.$t('large'),
          value: 1.15,
        },
        {
          text: this.$t('extra_large'),
          value: 1.3,
        }
      ]
    }
  },
  methods: {
    submit() {
      window.bus.$emit('zoomChanged', this.zoomScale);
    }
  },
  created() {
    if (this.$cookies && this.$cookies.get('zoom')) {
      this.zoomScale = parseFloat(this.$cookies.get('zoom'));
    }
  }
}
</script>
