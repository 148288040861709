<template>
  <div>
    <v-overlay :value="loading" class="text-center" opacity="0.75">
      <v-progress-circular indeterminate/>
      <p>
        {{ $t('loading') }}
      </p>
    </v-overlay>
  </div>
</template>

<script>

export default {
  name: 'Callback',
  data: () => ({
    errorMessages: [],
    loading: false
  }),
  created () {
    this.loading = true
    const state = localStorage.getItem('state');
    const codeVerifier = localStorage.getItem('codeVerifier');
    const code = this.$route.query.code;

    if (state !== this.$route.query.state || !code){
      this.$router.push({name: 'login'})
      return
    }

    this.fetchAccessToken(codeVerifier, code);
  },
  methods: {
    async fetchAccessToken(codeVerifier, code) {
      try {
        const token = await this.$store.dispatch('core/fetchAccessToken', {codeVerifier, code})
        if (token) {
          await this.$store.dispatch('core/getAuthUser')
          await this.$store.dispatch('core/redirect', {name: 'tasks'})
        } else {
          await this.$store.dispatch('core/handleError', new Error(this.$t('could_not_retrieve_authentication_token')))
          await this.$router.push({name: 'login'})
        }
      } catch (e) {
          this.loading = false
          await this.$store.dispatch('core/handleError', e)
          await this.$router.push({name: 'login'})
      }
    }
  }
}
</script>

