import crypto from "crypto";
import base64url from "base64url";
import config from "@/api/config";

export function challengeFromVerifier(codeVerifier) {
    const base64Digest = crypto
        .createHash("sha256")
        .update(codeVerifier)
        .digest("base64");

    return base64url.fromBase64(base64Digest);
}

export function generateAuthUrl(state, codeChallenge) {

    const authUrl = config.base_url + '/oauth/authorize?';

    const params = new URLSearchParams({
        client_id: config.client_id,
        redirect_uri: config.client_callback_base_url + '/callback',
        response_type: "code",
        scope: "",
        state: state,
        code_challenge: codeChallenge,
        code_challenge_method: "S256"
    });

    return authUrl + params.toString()
}
