import Vue from 'vue'

export default function setState(state, defaultState) {
    const defaultKeys = Object.keys(defaultState)
    // unset non-default values
    Object.keys(state)
        .filter(k => !defaultKeys.includes(k))
        .forEach(k => {
            Vue.delete(state, k)
        })
    // set default values
    Object.keys(defaultState).forEach(k => {
        Vue.set(state, k, defaultState[k])
    })
}