import { extend } from 'lodash'

let environmentVariables = process.env

if (window.ENV || window.env) {
    environmentVariables = extend(environmentVariables, window.ENV || window.env)
}

const falseValues = [false, 0, 'false', 'False', 'FALSE', '0'];
for (const key in environmentVariables) {
    if (falseValues.indexOf(environmentVariables[key]) !== -1) {
        environmentVariables[key] = false
    }
}

export const API_BASE_URL = environmentVariables.VUE_APP_API_BASE_URL
export const API_CLIENT_ID = environmentVariables.VUE_APP_CLIENT_ID
export const CLIENT_CALLBACK_BASE_URL = environmentVariables.VUE_APP_CLIENT_CALLBACK_BASE_URL
export const SESSION_ACTIVE_EVENTS = ['keydown', 'mousedown', 'touchend']
export const SESSION_TIMEOUT = parseInt(environmentVariables.VUE_APP_SESSION_TIMEOUT)
export const TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN = environmentVariables.VUE_APP_TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN
export const VERSION = environmentVariables.VUE_APP_VERSION
