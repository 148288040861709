<template>
  <v-card>
    <v-card-text>
      <div class="d-sm-flex justify-space-between align-center">
        <div class="d-flex relative w-full">
          <v-row>
            <v-col>
              <div class="mr-3">
                <p class="mb-1 secondary-14">
                  {{ $t('task') }}
                </p>
                <p class="mb-2 primary-24">
                  {{ id }}
                </p>
                <p class="mb-1">
                  <span class="secondary-16">{{ $t('client') }}</span> <span class="primary-16">{{ task.client_name }} ({{ task.client_id }})</span>
                </p>
                <p class="mb-1">
                  <span class="secondary-16">{{ $t('site') }}</span> <span class="primary-16">{{ task.site }}</span>
                </p>
                <p class="mb-0">
                  <span class="secondary-16">{{ $t('hub') }}</span> <span class="primary-16">{{ task.hub }}</span>
                </p>
              </div>
              <div class="absolute t-0 r-0 text-right justify-end secondary-16">
                <i class="fa-solid fa-calendar"></i> <span class="secondary-16">{{ date }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Task",
  props: {
    task: Object
  },
  computed: {
    client() {
      return `${this.task.client_name} (${this.task.client_id})`
    },
    id() {
      let paddedRequestId = this.task.request_id.toString().padStart(4, '0')
      let paddedSequenceId = this.task.sequence_id.toString().padStart(2, '0')
      return `${paddedRequestId}-${paddedSequenceId}`
    },
    date() {
      let date = new Date(this.task.due_date * 1000);
      return date.toLocaleDateString();
    },
  },
}
</script>

<style scoped>
  .relative {
    position: relative;
  }
  .t-0 {
    top: 0;
  }
  .r-0 {
    right: 0;
  }
  .w-full {
    width: 100%;
  }
</style>
