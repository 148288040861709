import HubooLocalisation from '@/localisation'
import axios from 'axios'
import App from '@/App.vue'
import IdleVue from 'idle-vue'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import router from '@/bootstrap/router'
import store from '@/bootstrap/store'
import vuetify from '@/plugins/vuetify'
import {
  SESSION_ACTIVE_EVENTS,
  SESSION_TIMEOUT,
  TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN
} from '@/bootstrap/config'
import {VueMasonryPlugin} from 'vue-masonry'
import {bus} from '@/bootstrap/bus'
import registerServiceWorker from "@/utilities/registerServiceWorker.js";
import VueCookies from 'vue-cookies'
import i18n from '@/plugins/i18n';

registerServiceWorker()

Vue.use(VueMasonryPlugin)
Vue.use(VueCookies)
Vue.use(VueI18n)
Vue.use(HubooLocalisation, {
  domainUrl: TRANSLATIONS_SERVICE_CLOUDFRONT_DOMAIN,
  axios,
  i18n,
});

// set default cookie config
Vue.$cookies.config('999d')

Vue.use(IdleVue, {
    idleTime: SESSION_TIMEOUT,
    events: SESSION_ACTIVE_EVENTS,
    eventEmitter: bus,
    store
})

let app;

Vue.prototype.$localise.handleLocaleChange()
    .then(() => {
        app = new Vue({
            el: '#app',
            i18n,
            router,
            store,
            vuetify,
            render: h => h(App)
        })
    });

export default app
