<template>
  <div>
    <v-divider class="mb-3"/>
    <v-overlay :value="loadingTasks" class="text-center" opacity="0.75">
      <v-progress-circular indeterminate/>
      <p>
        {{ $t('loading_tasks') }}
      </p>
    </v-overlay>
    <div
        class="mb-3"
        v-for="task in tasks"
        :id="'task_' + task.task_id"
        :key="task.task_id"
    >
      <router-link
          :to="{name: 'task-completion', params: { id: task.task_id }}"
          tag="div"
      >
        <task v-if="!task.completed_at" :task="task" class="pointer" />
      </router-link>
    </div>
    <div class="text-center">
      <v-pagination
          :value="page"
          @input="handlePage"
          :length="totalPages"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Task from '../tasks/Task'

export default {
  name: 'Tasks',
  components: {
    'task': Task
  },
  data: () => ({
    loadingTasks: true,
  }),
  computed: {
    tasks() {
      return this.$store.state.tasks.tasks
    },
    page() {
      return this.$store.state.tasks.page
    },
    totalPages() {
      return Math.ceil(this.$store.state.tasks.total / this.$store.state.tasks.perPage)
    },
    total() {
      return this.$store.state.tasks.total
    },
  },
  created() {
    this.getTasks()
  },
  methods: {
    handlePage(page) {
      this.$store.commit('tasks/setPage', page)
      this.getTasks()
    },
    async getTasks() {
      this.loadingTasks = true
      try {
        await this.$store.dispatch('tasks/getTasks')
      } finally {
        this.loadingTasks = false
      }
    },
  }
}
</script>

<style scoped>
caption {
  font-size: 0.75em;
}

.pointer {
  cursor: pointer;
}
</style>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.taken > * {
  opacity: 0.7;
}

@media #{map-get($display-breakpoints, 'sm-and-up')}{
  .v-btn.pick-button {
    height: 100%
  }
}
</style>
