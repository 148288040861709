<template>
  <v-app v-bind:style="{zoom: zoom}">
    <v-app-bar app elevation="0" color="transparent" absolute>
      <v-app-bar-nav-icon class="absolute" v-if="navigationEnabled" @click.stop="drawer = !drawer"/>
      <img class="mx-auto" :src="require('./assets/logo-os.svg')" height="30" alt="Logo" />
    </v-app-bar>
    <v-navigation-drawer v-if="navigationEnabled" v-model="drawer" app left temporary>
      <navigation/>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <errors/>
  </v-app>
</template>

<script>
import Navigation from '@/components/navigation/Default'
import Errors from '@/components/generic/Errors'
import {isHidden, getVisibilityEvent} from "@/utilities/global";
import {SESSION_ACTIVE_EVENTS} from "@/bootstrap/config";

export default {
  name: 'App',
  components: {
    'navigation': Navigation,
    'errors': Errors
  },
  data: () => ({
    drawer: false,
    visibilityEvents: {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    },
    zoom: 1,
  }),
  computed: {
    title() {
      return this.$store.getters['core/getTitle']
    },
    navigationEnabled() {
      return this.$store.getters['core/isAuthenticated']
    },
  },
  onIdle() {
    this.$store.dispatch('core/logout')
  },
  created() {
    for (const key in SESSION_ACTIVE_EVENTS) {
      window.addEventListener(SESSION_ACTIVE_EVENTS[key], this.setActiveSessionTime)
    }
    this.checkActiveSession()
    if (getVisibilityEvent()) {
      document.addEventListener(getVisibilityEvent(), this.checkActiveSession)
    }
    if (this.$cookies) {
      this.zoom = this.$cookies.get('zoom');
      window.bus.$on('zoomChanged', (zoom) => {
        this.$cookies.set('zoom', zoom);
        this.zoom = zoom;
      });
    }
  },
  beforeDestroy() {
    for (const key in SESSION_ACTIVE_EVENTS) {
      window.removeEventListener(SESSION_ACTIVE_EVENTS[key], this.setActiveSessionTime)
    }
    document.removeEventListener(getVisibilityEvent(), this.checkActiveSession)
  },
  methods: {
    setActiveSessionTime: function () {
      this.checkActiveSession()
      this.$store.commit('core/setActiveSessionTime');
    },
    checkActiveSession() {
      if (!isHidden()) {
        this.$store.dispatch('core/checkActiveSession')
      }
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.container {
  @media #{map-get($display-breakpoints, 'md-and-up')}{
    max-width: #{map-get($grid-breakpoints, 'md')} !important;
  }
}

.absolute {
  position: absolute;
}

.v-card__title {
  word-break: break-word !important;
}

.v-application {
  border-top: 5px solid #38D5AF;
  background: #E9EBF0 !important;
  font-family: "Open Sans", sans-serif;
}

.secondary-16 {
  color: #3B4675; /* purple */
  font-size: 16px;
  letter-spacing: 0.29px;
  font-weight: 400;
}

.secondary-14 {
  color: #3B4675; /* purple */
  font-size: 14px;
  letter-spacing: 0.25px;
  font-weight: 400;
}

.primary-16 {
  color: #292D4F; /* High Emphasis */
  font-size: 16px;
  letter-spacing: 0.29px;
  line-height: 24px;
  font-weight: 400;
}

.primary-24 {
  color: #292D4F; /* High Emphasis */
  font-size: 24px;
  letter-spacing: 0.68px;
  line-height: 24px;
  font-weight: 400;
}

.secondary {
    color: #292D4F !important;
}

</style>
