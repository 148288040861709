<template>
    <div class="d-flex align-center justify-center">
      <v-btn
          :href="authUrl"
          id="login_btn"
          :loading="loading"
          color="secondary"
      >
        {{ $t('login') }}
      </v-btn>
    </div>
</template>

<script>
import randomstring from "randomstring";
import {challengeFromVerifier, generateAuthUrl} from "@/utilities/pkce";

export default {
  name: 'Login',
  data: () => ({
    loading: false,
    authUrl: null
  }),
  created() {
    this.getAuthUrl();
  },
  methods: {
    async getAuthUrl() {
      const state = randomstring.generate(40);
      localStorage.setItem('state', state);

      const codeVerifier = randomstring.generate(128);
      localStorage.setItem('codeVerifier', codeVerifier);

      let codeChallenge = challengeFromVerifier(codeVerifier);

      this.authUrl = generateAuthUrl(state, codeChallenge);
    }
  }
}
</script>
