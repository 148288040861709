const visibilityEvents = {
    hidden: "visibilitychange",
    webkitHidden: "webkitvisibilitychange",
    mozHidden: "mozvisibilitychange",
    msHidden: "msvisibilitychange"
}

function getVisibilityKey(visibilityEvents) {
    for (const stateKey in visibilityEvents) {
        if (stateKey in document) {
            return stateKey
        }
    }
    return null
}

export function isHidden(visibilityKey = visibilityEvents) {
    return document[getVisibilityKey(visibilityKey)]
}

export function getVisibilityEvent(visibilityKey = visibilityEvents) {
    if (getVisibilityKey(visibilityKey) === null) {
        return null
    }
    return visibilityEvents[getVisibilityKey(visibilityKey)]
}