<template>
  <span id="localisation-handler"/>
</template>

<script>
export default {
  async beforeCreate () {
    await this.$localise.handleLocaleChange();

    if (this.browserLocale !== this.$i18n.fallbackLocale) {
      await this.$localise.fetchFallbackLocale();
    }
  },
  computed: {
      browserLocale () {
          return this.$localise.getBrowserLocale();
      },
  },
  watch: {
      browserLocale: {
          handler (newLocale) {
              if (newLocale) this.$localise.handleLocalechange(newLocale);
          },
      },
  },
};
</script>
