const FILE_TYPE = '.json';
const CONFIG_FILE_NAME = '/config';
const TRANSLATIONS_ROOT_PREFIX = '/vue/';

export default class {
    /**
     * @param axios
     * @param i18n
     * @param url
     * @param setLanguageHandler
     */
    constructor (axios, i18n, url, setLanguageHandler = null) {
        this.axios = axios;
        this.i18n = i18n;
        this.url = url;
        this.setLanguageHandler = setLanguageHandler;

        this.availableLocales = [];
        this.loadedLocales = [];
        this.config = null;
    }

    fetchConfig () {
        return this.axios
            .get(this.getConfigUrl())
            .then(res => {
                return res.data;
            })
            .catch(error => {
                console.error('fetch localisation config error:', error);
            });
    }

    /**
     * @param lang
     */
    fetchLanguage (lang) {
        return this.axios
            .get(this.getLanguageUrl(lang))
            .then(res => {
                return res.data;
            })
            .catch(error => {
                console.error('fetch localisation language error:', error);
            });
    }

    fetchAndSetConfig () {
        return this.fetchConfig()
            .then(res => {
                this.setConfig(res);
                return res;
            });
    }

    /**
     * @param lang
     */
    fetchAndSetLanguage (lang) {
        return this.fetchLanguage(lang)
            .then((res) => {
                this.setLanguage(lang, res, true);
                return res;
            });
    }

    async fetchFallbackLocale () {
        if (!this.config) await this.fetchAndSetConfig();
        const lang = this.i18n.fallbackLocale;
        const messages = await this.fetchLanguage(lang);
        this.i18n.setLocaleMessage(lang, messages);
        this.loadedLocales.push(lang);
    }

    getBrowserLocale () {
        const browserLocale = navigator.language || navigator.userLanguage;
        if (!browserLocale) return undefined;
        return browserLocale.trim().split(/-|_/)[0];
    }

    getConfigUrl () {
        return this.url + CONFIG_FILE_NAME + FILE_TYPE;
    }

    /**
     * @param lang
     */
    getLanguageUrl (lang) {
        return this.url + TRANSLATIONS_ROOT_PREFIX + lang + FILE_TYPE;
    }

    /**
     * @param lang
     */
    async handleLocaleChange (lang) {
        if (!lang) lang = this.getBrowserLocale();
        if (!this.config) await this.fetchAndSetConfig();

        switch (true) {
            case this.i18n.locale === lang:
            case this.loadedLocales.includes(lang):
                return Promise.resolve(this.setLanguage(lang));
            case !this.availableLocales.includes(lang):
                return this.loadedLocales.includes(this.i18n.fallbackLocale) ?
                    Promise.resolve(this.setLanguage(this.i18n.fallbackLocale)) :
                    this.fetchAndSetLanguage(this.i18n.fallbackLocale);
            default:
                return this.fetchAndSetLanguage(lang);
        }
    }

    /**
     * @param config
     */
    setConfig (config) {
        this.config = config;
        this.i18n.defaultLocale = config.default_locale;
        this.i18n.fallbackLocale = config.fallback_locale;
        this.availableLocales = config.available_locales;
    }

    /**
     * @param lang
     * @param messages
     * @param newLanguage
     */
    setLanguage (lang, messages, newLanguage = false) {
        if (newLanguage) {
            this.i18n.setLocaleMessage(lang, messages);
            this.loadedLocales.push(lang);
        }
        this.i18n.locale = lang;
        this.axios.defaults.headers.common['Accept-Language'] = lang;
        document.querySelector('html').setAttribute('lang', lang);
        if (typeof this.setLanguageHandler === 'function') this.setLanguageHandler(lang);
    }
}
